import styles from './styles.module.css'

export function Root() {
  return (
    <div className={styles.Wrap}>
      <div className={styles.Content}>
        <div className={styles.Profile}>
          <div className={styles.ProfilePic}>
            <img
              src="https://junkiejeff.com/profile.jpg"
              alt="@JunkieJeffStudio"
            />
          </div>
          <div className={styles.ProfileName}>
            Junkie
            <br />
            Jeff
            <br />
            Studio
          </div>
        </div>
      </div>

      <div className={styles.Links}>
        <a href="https://www.youtube.com/@JunkieJeffStudio" target="_blank">
          YouTube
        </a>
        <a href="https://www.instagram.com/junkiejeffstudio" target="_blank">
          Instagram
        </a>
        <a href="https://twitter.com/JunkieJeffOnX" target="_blank">
          Twitter/X
        </a>
        <a href="https://www.tiktok.com/@junkiejeffstudio" target="_blank">
          TikTok
        </a>
      </div>

      <div className={styles.Inquiries}>
        <a href="mailto:inquiries@junkiejeff.com">
          inquiries<span>@junkiejeff.com</span>
        </a>
      </div>
    </div>
  )
}
