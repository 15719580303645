import { Route, Routes } from 'react-router-dom'
import { Root } from './Root'

export function JunkieJeffRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Root />} />
    </Routes>
  )
}
