import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import ErrorBoundary from '~/app/preseed-table/component/ErrorBoundary'
import './index.css'
import { JunkieJeffRoutes } from './route'

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <JunkieJeffRoutes />
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)
